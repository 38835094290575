import { useRoute } from 'vue-router';
import { createSharedComposable } from '@/features/base/createSharedComposable';
import { config } from '@/config';
import { parseNumber } from '@/utils/number/parseNumber';

export const sharedConfig = createSharedComposable(() => {
  const route = useRoute();
  const isDev = Boolean(config.isDev || route.query.dev);
  const queryId = route.query.id;
  const queryData = route.query.data;
  const queryPartnerId = parseNumber(route.query.partnerId);
  const queryPartnerTgBotId = parseNumber(route.query.partnerTgBotId);
  const queryPublisherAccountId = parseNumber(route.query.publisherAccountId);
  const queryTgBotId = parseNumber(route.query['tg-bot-id']);

  return {
    isDev,
    queryId,
    queryPartnerId,
    queryData,
    queryTgBotId,
    queryPartnerTgBotId,
    queryPublisherAccountId,
  };
});
