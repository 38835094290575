import {
  computed, ref, shallowRef, unref,
} from 'vue';
import { parseBaseError } from 'parse-base-error';
import { authTgWebApp } from '@/api/authTgWebApp';
import { Account } from '@/api/types/Account';
import { createSharedComposable } from '@/features/base/createSharedComposable';
import { sharedTelegramWebApp } from '@/features/shared/sharedTelegramWebApp';
import { sharedConfig } from '@/features/shared/sharedConfig';
import { requestDate } from '@/api/requestDate';
import { useRoute } from 'vue-router';

const TEST_TG_INIT_DATA = 'query_id=AAGYXWo7AAAAAJhdajubjkG_&user=%7B%22id%22%3A996826520%2C%22first_name%22%3A%22YouGen%22%2C%22last_name%22%3A%22Support%22%2C%22username%22%3A%22findvi%22%2C%22language_code%22%3A%22ru%22%7D&auth_date=1687101872&hash=cb44fd8d262aad1037d407aefcefb5ae90d4259aff0b4bf06e6c71d0e5f377ad';

export const sharedAccount = createSharedComposable(() => {
  const { isDev, queryTgBotId } = sharedConfig();
  const account = shallowRef<Account | undefined>();
  const initializedAuthorization = ref(false);
  const loadingAuthorization = ref(false);
  const authorizationErrorMessage = ref('');
  const authErrorMessage = ref('');
  const route = useRoute();
  const { telegramWebApp } = sharedTelegramWebApp();
  /** COMPUTED */
  const isAuthorized = computed(() => Boolean(unref(account)));
  /** METHODS */
  const authByTelegramWebApp = async (tgInitData: string) => {
    telegramWebApp.ready();
    loadingAuthorization.value = true;
    const data = await authTgWebApp({
      initData: tgInitData,
      tgBotId: queryTgBotId,
    });

    if (data.success) {
      account.value = data.data;
    } else {
      const apiDate = await requestDate();
      authErrorMessage.value = `Ошибка авторизации, попробуй еще раз (${data.error.plainMessage} ${apiDate} )`;
      authorizationErrorMessage.value = JSON.stringify(data.error);
      parseBaseError(data.error, 'authByWebAppInitData').log();
    }

    loadingAuthorization.value = false;
  };
  const authAccount = async () => {
    try {
      if (telegramWebApp.initData) {
        await authByTelegramWebApp(telegramWebApp.initData);
      } else if (isDev) {
        await authByTelegramWebApp(TEST_TG_INIT_DATA);
      } else {
        authErrorMessage.value = 'Ошибка авторизации (code 2)';
      }
    } catch (e) {
      authErrorMessage.value = 'Ошибка авторизации (code 999)';
    } finally {
      initializedAuthorization.value = true;
    }
  };

  return {
    account,
    isAuthorized,
    loadingAuthorization,
    initializedAuthorization,
    authorizationErrorMessage,
    authErrorMessage,
    authAccount,
  };
});
