import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import TgAppLayout from '@/components/TgAppLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: () => import(/* webpackChunkName: "HomePage" */ '@/views/HomePage.vue'),
  },
  {
    path: '/test',
    component: () => import(/* webpackChunkName: "TestPage" */ '@/views/TestPage.vue'),
  },
  {
    path: '/play-video',
    component: () => import(/* webpackChunkName: "PlayVideoPage" */ '@/views/PlayVideoPage.vue'),
  },
  {
    path: '/tg-app',
    alias: ['/private'],
    component: TgAppLayout,
    children: [
      // {
      //   path: '/video-search',
      //   name: 'videoSearch',
      //   component: () => import(/* webpackChunkName: "VideoSearch" */ '@/views/VideoSearch.vue'),
      // },
      {
        // TODO убрать через некоторое время
        path: 'video/:videoId',
        name: 'video-player',
        component: () => import(/* webpackChunkName: "WebAppPlayerPage" */ '@/views/WebAppAdPlayerPage.vue'),
      },
      {
        path: 'movie/:movieId',
        name: 'movie-by-id',
        component: () => import(/* webpackChunkName: "WebAppMovieByIdPage" */ '@/views/WebAppMovieByIdPage.vue'),
      },
      {
        path: 'ad-player',
        name: 'ad-player',
        component: () => import(/* webpackChunkName: "WebAppPlayerPage" */ '@/views/WebAppAdPlayerPage.vue'),
      },
      {
        path: 'select',
        name: 'select',
        component: () => import(/* webpackChunkName: "WebAppSelectPage" */ '@/views/WebAppSelectPage.vue'),
      },
      {
        path: 'payment-order/:orderId',
        name: 'payment-order',
        component: () => import(/* webpackChunkName: "WebAppPaymentOrderPage" */ '@/views/WebAppPaymentOrderPage.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
